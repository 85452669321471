import lightbox from 'lightbox2/dist/js/lightbox.min.js';
import Swiper from 'swiper';
import {TABS, SubTabs, hideTabs} from './libs/tabs';
import burger from './libs/burger';
import VALIDATION from './libs/validation';
import Inputmask from "inputmask";
import {find} from 'lodash';
import axios from "axios";
import {list} from "postcss";
import Select from "select2";
import SimpleLightbox from "simplelightbox";

axios.interceptors.request.use(function (config) {
    config.headers['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    return config;
});

global.$ = global.jQuery = require('jquery');

const news_wrap = document.querySelector('.news');
const calendar_wrap = document.querySelector('.calendar');
const competition_wrap = document.querySelector('.competition-page');
const newsPage_wrap = document.querySelector('.news-page');
const federation_wrap = document.querySelector('.federation-page');
const user_content = document.querySelector('.user__content');
const results_wrap = document.querySelector('.results-page');
const results__standart = document.querySelector('.results-page .results__standart');
const results__best = document.querySelector('.results-page .results__best');
const results__order = document.querySelector('.results-page .results__order');
const results__rating = document.querySelector('.results-page .results__rating');
burger();
TABS(news_wrap);
TABS(calendar_wrap);
TABS(competition_wrap);
TABS(newsPage_wrap);
TABS(federation_wrap);
TABS(user_content);
TABS(results_wrap);
SubTabs(calendar_wrap);
SubTabs(results__standart);
SubTabs(results__best);
SubTabs(results__order);
SubTabs(results__rating);
SubTabs(federation_wrap);
hideTabs(calendar_wrap);

/*--------- SLIDERS ---------*/
new Swiper('.top-banner', {
    loop: true,
    direction: 'horizontal',
    pagination: {
        el: '.top-banner__pagination',
        type: 'fraction',
    },
    navigation: {
        nextEl: '.top-banner__button-next',
        prevEl: '.top-banner__button-prev',
    },
})
new Swiper('.news__slider', {
    // loop: true,
    direction: 'horizontal',
    spaceBetween: 20,
    // autoHeight: true,
    observer: true,
    observeParents: true,
    breakpoints: {
        1600: {
            slidesPerView: 3.7,
        },
        1200: {
            slidesPerView: 3,

        },
        1024: {
            slidesPerView: 2.5,
        },
        768: {
            slidesPerView: 2,

        },
        560: {
            slidesPerView: 1.5,
        },
        320: {
            slidesPerView: 1,
        }
    },
    pagination: {
        el: '.news__pagination',
        clickable: true
    },
    navigation: {
        nextEl: '.news__button-next',
        prevEl: '.news__button-prev',
    },
})
new Swiper('.partners__slider', {
    // loop: true,
    direction: 'horizontal',
    spaceBetween: 30,
    slidesPerView: 'auto',
    observer: true,
    observeParents: true,
    pagination: {
        el: '.partners__pagination',
        clickable: true
    },
    navigation: {
        nextEl: '.partners__button-next',
        prevEl: '.partners__button-prev',
    },
    768: {
        slidesPerView: 1,
        spaceBetween: 30,
    },
    320: {
        spaceBetween: 5,
    }
})
new Swiper('.gallery__slider', {
    slidesPerView: 1,
    simulateTouch: false,
    observer: true,
    observeParents: true,
    pagination: {
        el: '.gallery__nav--pagination',
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
        },
    },
    navigation: {
        nextEl: '.gallery__nav--control--next',
        prevEl: '.gallery__nav--control--prev',
    },
})
/*--------- SLIDERS END ---------*/

$(function () {
    new SimpleLightbox('.results-gallery a');
    $('.js-view-block').on('click', function (e) {
        $(this).addClass('active');
        // $('.js-view-list').removeClass('active');
        $(this).siblings().removeClass('active');
        $(this).closest('.federation__documents').addClass('view-block');
        $(this).closest('.federation__documents').removeClass('view-list');
    });
    $('.js-view-list').on('click', function (e) {
        $(this).addClass('active');
        // $('.js-view-block').removeClass('active');
        $(this).siblings().removeClass('active');
        $(this).closest('.federation__documents').addClass('view-list');
        $(this).closest('.federation__documents').removeClass('view-block');
    });

    $('.login-form').on('submit', function (e) {
        e.preventDefault()

        let self = this;

        [...self.querySelectorAll('.error')].forEach((el, key) => {
            el.remove()
        })

        axios.post(self.getAttribute('data-ajax_action'), {
            email: self.email.value,
            password: self.password.value,
            redirect: self.redirect ? self.redirect.value : null
        })
            .then((res) => {
                window.location.href = res.data.link
            })
            .catch(error => {
                Object.keys(error.response.data.errors).forEach((key) => {
                    let parent = self[key],
                        errorElement = document.createElement("p");

                    errorElement.innerText = error.response.data.errors[key][0];
                    errorElement.classList.add('error');
                    parent.parentElement.append(errorElement);
                })
            })
    })

    $('.reset-password-form').on('submit', function (e) {
        e.preventDefault();

        let self = this,
            data = {
                email: self.email.value,
            };

        [...self.querySelectorAll('.error')].forEach((el, key) => {
            el.remove()
        })

        axios.post(self.getAttribute('data-ajax_action'), data)
            .then((res) => {
                // alert(res.data);
                $('.reset-password-form').addClass('hidden');
                $('.popup__wrapper').append('<p class="massage">' + res.data.email + '</p>')
                setTimeout(() => {
                    $('.reset-password-form').removeClass('hidden');
                    $('.popup').removeClass('active');
                    if (window.innerWidth > 1024) {
                        $('body').removeClass('active');
                    }
                    $('.popup__wrapper .massage').remove();
                }, 5000);
            })
            .catch(error => {
                Object.keys(error.response.data).forEach((key) => {
                    let parent = self[key],
                        errorElement = document.createElement("p");

                    errorElement.innerText = error.response.data[key];
                    errorElement.classList.add('error');
                    parent.parentElement.append(errorElement);
                })
            })
    })

    $('.create-payment-request').on('click', function (e) {
        e.preventDefault();

        let form = $(this).closest('form');

        axios.post(form.attr('action'), form.serialize())
            .then((res) => {
                window.location.href = res.data.paymentRequest.formUrl
            })
            .catch((error) => {
                alert(error.response.data.message);
            })
    })

    /*search club start*/
    $(".clubs-select #club, .search-clubs #search").on('keyup', function () {
        let _this = this;
        if ($(this).val().length > 2) {
            $.each($(".clubs-wrap .club"), function () {
                if ($(this).text().toLowerCase().indexOf($(_this).val().toLowerCase()) === -1) {
                    $(this).hide();
                    $(_this).closest(".clubs-select , .search-clubs").find(".clubs-wrap").show();
                } else {
                    $(this).show();
                }
            })
        }
    });

    $(".clubs-select #club, .search-clubs #search").on('blur', function () {
        let that = this;
        setTimeout(function () {
            $(that).closest(".clubs-select , .search-clubs").find(".clubs-wrap").hide();
        }, 300);
    });

    $('.clubs-wrap .club').on('click', function () {
        $(this).closest(".clubs-select , .search-clubs").find("input").val($(this).text());
        console.log($('#club').val());
        setTimeout(function () {
            $('.clubs-select #club , .search-clubs #search').blur();
        }, 300);

    });
    /*search club end*/

    $('.tabs-nav-wrap .mob-selector--arrow').on('click', function (e) {
        $(this).closest('.mob-selector').next().toggleClass('active');
    });
    $('.tabs-nav-wrap .tabs__nav li').on('click', 'button', function (e) {
        $(this).closest('.tabs-nav-wrap').find('.mob-selector').next().removeClass('active');
        $(this).closest('.tabs-nav-wrap').find('.mob-selector .mob-selector--text').attr('data-text', $(this).attr('data-text'));
    });
    $('.registration__weapon-data input').on('keyup', (e) => {
        if ($(e.target).val().length > 0) {
            $(e.target).closest('label').siblings('div').find('button').addClass('active')
        } else {
            $(e.target).closest('label').siblings('div').find('button').removeClass('active')
        }
    });

    $('[name=profile-photo]').on('change', function () {
        let data = new FormData,
            self = this;
        data.append('profile-photo', this.files[0])

        axios.post(this.getAttribute('data-upload-path'), data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(response => {
            $(self).closest('.photo').find('img')[0].src = response.data.path
        }).catch(error => {
            console.error(error)
        })
    })

    $('#add-weapon, #add-ammo').on('click', (e) => {
        e.preventDefault();

        let targetBlock = $(e.target).closest('[class^=registration]'),
            lastAvailableInput = targetBlock.find('input').last(),
            cloningInput = lastAvailableInput.clone();

        if (!lastAvailableInput.val()) {
            return false;
        }

        cloningInput.val('')
        targetBlock.find('label').append(cloningInput)

        targetBlock.find('button').last().addClass('show')
    });

    $('#remove-weapon, #remove-ammo').on('click', (e) => {
        e.preventDefault();

        let targetBlock = $(e.target).closest('[class^=registration]'),
            listOfInputs = targetBlock.find('input');

        if (listOfInputs.length > 1) {
            listOfInputs.last().remove()

            if (listOfInputs.length === 2) {
                targetBlock.find('button').last().removeClass('show')
            }
        }
    });

    $('.filter-btn').on('click', function () {
        if ($('.federation-page')) {
            $('.federation__filters').addClass('show');
        }
        if ($('.clubs-page')) {
            $('.districts-list').addClass('show');
        }
        $('body').addClass('active');
    })
    $('.close-filters').on('click', function () {
        if ($('.federation-page')) {
            $('.federation__filters').removeClass('show');
        }
        if ($('.clubs-page')) {
            $('.districts-list').removeClass('show');
        }

        $('body').removeClass('active');
    })
    $('.sort-btn').on('click', function () {
        if ($('.federation-page')) {
            $('.federation__result_view').addClass('show');
        }
        $('body').addClass('active');
    })
    $('.close-filters', '.federation__result_view').on('click', function () {
        if ($('.federation-page')) {
            $('.federation__result_view').removeClass('show');
        }

        $('body').removeClass('active');
    })

    $('.federation-search-form').on('change', '[name=orderBy], [type=checkbox], [type=radio]', function () {
        $(this).closest('form').submit()
    })

    $('.open-login-popup').on('click', function (e) {
        e.preventDefault()
        if ($(this).data('redirect')) {
            let redirect = $(this).data("redirect")
            $(".login-form").append(`<input type="hidden" name="redirect" value="${redirect}">`)
        }
        openLoginPopup()
    })

    $('.open-reset-password-popup').on('click', function (e) {
        e.preventDefault();

        $('.popup__sign-in').removeClass('active');
        $('.popup__reset-password').addClass('active');
        $('body').addClass('active');
    })

    $('.popup').on('click', function (e) {
        if ((e.target === this || e.target === $(this).find($('.popup__close'))[0])) {
            $('.popup').removeClass('active');
            if (window.innerWidth > 1024) {
                $('body').removeClass('active');

            }
        }
    })
    $('.results__filter-btn').on('click', function () {
        $('.results__filter_list').addClass('active');
    })
    $('.results__filter_list .close').on('click', function () {
        $('.results__filter_list').removeClass('active');
    })

    $('#competition-filter-form').on('input', '[type=radio]', function () {
        $(this).closest('form').trigger('submit')
    })

    $('.calendar__years-list').on('click', 'li', function (e) {
        let year = $('[name=year]').val($(this).html())
        year.closest('form').trigger('submit')
    })

    if ($('main.registration-event-page').length) {
        setCompetitionPrice()
    }

    $('.registration__club-data').on('change', '[name=competition_club_id]', function () {
        setCompetitionPrice()
    })
});

function setCompetitionPrice() {
    axios.get(`/competitions/${$('[name=competition_id]').val()}/price?competition_club_id=${$('[name=competition_club_id]').val()}`)
        .then(res => {
            $('.payment__info .price span:nth-child(2)').html(res.data.price)
        })
}

[...(document.querySelectorAll('.js-tabs a'))].forEach((el) => {
    el.addEventListener('click', function (e) {
        if (!e.target.classList.contains('active'))
            e.preventDefault()
    })
})

$('.search-clubs [name=search], .federation-page [name=search]').on('input', function () {
    $('[name=search]').val($(this).val())
})

$(".search-clubs button").on("click", function () {
    $(".sort-form").trigger("submit");
})

$('.clubs-page .districts-list ul > li').on('click', function () {
    $(this).toggleClass('active').siblings().removeClass('active');

    if ($(this).hasClass('active')) {
        $('.location').addClass('hidden')

        $(`.location[data-region='${$(this).data('region')}']`).removeClass('hidden')
        setSearchRegion($(this).data('region'));
    } else {
        setSearchRegion('');
    }
});

$('.clubs-page .location').on('click', function () {
    $(this).toggleClass('hidden');
    $('.districts-list ul li').removeClass('active');

    if ($(this).hasClass('hidden')) {
        $(this).removeClass('hidden')

        if ($(this).siblings('.hidden').length) {
            $(this).siblings('g').removeClass('hidden')

            setSearchRegion('')
        } else {
            $(this).siblings('g').addClass('hidden')
            $(`.districts-list ul li[data-region='${$(this).data('region')}']`).addClass('active')

            setSearchRegion($(this).data('region'))
        }
    }

    if (!$(this).hasClass('hidden') && $(this).siblings('.hidden').length) {
        $(this).siblings('g').addClass('hidden')
        $(`.districts-list ul li[data-region='${$(this).data('region')}']`).addClass('active')

        setSearchRegion($(this).data('region'))
    }
});

$('.federation-page .districts-list ul li p').on('click', function () {
    let parent = $(this).closest('li');
    parent.toggleClass('active').siblings().removeClass('active');

    if (parent.hasClass('active')) {
        $('.location').addClass('hidden')

        $(`.location[data-region='${parent.data('region')}']`).removeClass('hidden')
        setSearchRegion(parent.data('region'));
    } else {
        setSearchRegion('');
    }
});

$('.federation-page .districts-list .list-district').on('click', 'div', function () {
    $(this).toggleClass('active').siblings().removeClass('active');
    $('[name="child-region"]').val($(this).hasClass('active') ? $(this).data('id') : '')
    $(".sort-form").trigger("submit");
});

$('.federation-page .location').on('click', function () {
    $(this).toggleClass('hidden');
    $('.districts-list ul li').removeClass('active');

    if ($(this).hasClass('hidden')) {
        $(this).removeClass('hidden')

        if ($(this).siblings('.hidden').length) {
            $(this).siblings('g').removeClass('hidden')

            setSearchRegion('')
        } else {
            $(this).siblings('g').addClass('hidden')
            $(`.districts-list ul li[data-region='${$(this).data('region')}']`).addClass('active')

            setSearchRegion($(this).data('region'))
        }
    }

    if (!$(this).hasClass('hidden') && $(this).siblings('.hidden').length) {
        $(this).siblings('g').addClass('hidden')
        $(`.districts-list ul li[data-region='${$(this).data('region')}']`).addClass('active')

        setSearchRegion($(this).data('region'))
    }
});

$('.federation-page [name=search]').on('input', function () {
    let val = $(this).val();
    if (val.length > 2)
        axios.get(`/judges/live-search?search=${val}`)
            .then((res) => {
                $('.clubs-wrap').css('display', 'block')
                $('.clubs-wrap').html(res.data.html)
            })
            .catch((error) => {

            })
})

$('.federation-page .sort-form').on('click', '.club', function () {
    $('[name=search]').val($(this).html()).closest('form').trigger('submit')
})

function setSearchRegion(region) {
    $('[name=region]').val(region)
    $('[name="child-region"]').val('')

    $(".sort-form").trigger("submit");
}

$('.federation-page .federation__filters_mob-buttons .reset').on('click', function () {
    $('[name=region]').val('');
    $('[name="child-region"]').val('');
    $('[name="types[]"]').removeAttr('checked');
    $('[name="categories[]"]').removeAttr('checked');

    $(".sort-form").trigger("submit");
});

$('.federation-page .federation__result_view-buttons .reset').on('click', function () {
    $('[name="orderBy"]').removeAttr('checked');
    $('[name="per_page"]').removeAttr('checked');
    $('#credentials').attr("checked", true);
    $('#per_page10').attr("checked", true);
    $(".sort-form").trigger("submit");
});


function openLoginPopup() {
    $('.popup__sign-in').addClass('active');
    $('body').addClass('active');
}

let tel_inp = document.querySelector('input[type="tel"]');
if (tel_inp) {
    let im = new Inputmask("+7 (999) 999-99-99");
    im.mask(tel_inp);
}

let date_inp = document.querySelector('[name="birth_date"]');
if (date_inp) {
    let _mask = new Inputmask("99.99.9999");
    _mask.mask(date_inp);
}

if (window.location === window.parent.location) {
    document.body.style.visibility = 'visible'
    document.body.style.overflow = 'auto'
}

$(".js-select").select2({});

$('.registration-event__form').on('submit', function (e) {
    e.preventDefault();
    if (VALIDATION(this)) {

        $('.registration').addClass('hidden');
        $('.payment').removeClass('hidden');
        $("html, body").animate({
            scrollTop: $("body").offset().top
        }, 1000);
    }
})

$('document').ready(() => {
    setTimeout(() => {
        $('.fill-ranking-link.active').trigger('click')
    }, 200)
})

$('.fill-ranking-link').on('click', function () {
    let linkData = $(this).data('params');

    $('.athletes-rating-replaceable-link').remove();
    if (linkData)
        $('.results__best_filter-download').append(`<a href="${linkData.link}" class="results__absolute athletes-rating-replaceable-link" ${linkData.target ? 'target="_blank"' : ''} >${linkData.text}</a>`)
})
