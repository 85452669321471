export default () => {
    $('.burger').on('click', function() {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $('.header--wrap-menu').removeClass('active');
            $('html').removeClass('active');
        } else {
            $(this).addClass('active')
            $('.header--wrap-menu').addClass('active');
            $('html').addClass('active');
        }
        return false;


    })

    $('.header__lang-btn').on('click', function(e) {
        e.preventDefault();
        if ($(this).is('visible')) {
            $(this).next().slideUp();
        } else {
            $(this).next().slideToggle();
        }
        return false;
    })
    $('.calendar__years-btn').on('click', function(e) {
        e.preventDefault();
        if ($(this).is('visible')) {
            $(this).next().slideUp();
        } else {
            $(this).next().slideToggle();
        }
        return false;
    })
    $('.federation__result_quantity-btn').on('click', function(e) {
        e.preventDefault();
        if ($(this).is('visible')) {
            $(this).next().slideUp();
        } else {
            $(this).next().slideToggle();
        }
        return false;
    })
    $('.header__dropdown-btn').on('click', function(e) {
        e.preventDefault();
        if ($(this).is('visible')) {
            $(this).next().slideUp();
        } else {
            $(this).next().slideToggle();

        }
        return false;
    })
    $('.header__dropdown-menu li a').on('click', function(e) {
        localStorage.setItem('index', $(this).closest($('li')).index())
        console.log(localStorage.index)
    })

}