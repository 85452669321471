function TABS(wrap) {
    window.document.addEventListener('DOMContentLoaded', () => {

        if (!wrap) {
            return;
        }
        const TABS = wrap.querySelector('.js-tabs');
        const TABS_BTNS = TABS.querySelectorAll('button, a');
        const TABS_CONTENTS = wrap.querySelectorAll('.js-tabs-item');

        if ([...TABS_BTNS].findIndex((item) => item.classList.contains('active')) < 0) {
            [...TABS_BTNS][0].classList.add('active');
            [...TABS_CONTENTS][0].classList.add('active');
        }

        //for sub-tabs on mobile version
        const CLEAN_ALL_TABS = () => {
            [...TABS_BTNS].forEach((btn) => {
                btn.classList.remove('active');
            });
            [...TABS_CONTENTS].forEach((content) => {
                content.classList.remove('active');
            });
        }

        [...TABS_BTNS].forEach((btn, index) => {
            btn.addEventListener('click', (event) => {
                CLEAN_ALL_TABS();
                //clear exist active item and show first
                let currActiveContentItems = wrap.querySelector('.js-tabs-item.active');
                if (currActiveContentItems) {
                    currActiveContentItems.classList.remove('active')
                }
                btn.classList.add('active');
                [...TABS_CONTENTS][index].classList.add('active');
            });
        });
        if (wrap === document.querySelector('.federation-page , .results-page') && localStorage.index) {
            [...TABS_BTNS].forEach((btn) => {
                btn.classList.remove('active');
            });
            [...TABS_CONTENTS].forEach((content) => {
                content.classList.remove('active');
            });
            [...TABS_BTNS][localStorage.index].classList.add('active');
            [...TABS_CONTENTS][localStorage.index].classList.add('active');
            localStorage.clear();
            return;
        }
    });
}

function hideTabs(wrapper) {
    if (!wrapper) {
        return;
    }
    let wrap = wrapper.querySelectorAll('.js-tabs-item');
    if (window.innerWidth < 1025) {
        [...wrap].forEach((item) => {
            let BTN = item.querySelector('.trigger');
            let items = item.querySelectorAll('.calendar__event');
            if (!BTN) {
                return;
            }
            BTN.classList.add('active');
            [...items].forEach((el, index) => {
                if (index > 2) {
                    el.classList.add('hidden');
                }
            })
            BTN.addEventListener('click', () => {
                BTN.classList.toggle('active');
                [...items].forEach((el, index) => {
                    if (index > 2) {
                        el.classList.toggle('hidden');
                        return
                    }
                })
            })
        })
    }
}

function SubTabs(wrap) {
    window.document.addEventListener('DOMContentLoaded', () => {
        if (!wrap) {
            return
        }
        let TABS = wrap.querySelector('.js-subtabs');
        if (!TABS) {
            return;
        }
        const TABS_BTNS = TABS.querySelectorAll('button');
        const TABS_CONTENTS = wrap.querySelectorAll('.js-subtabs-item');

        [...TABS_BTNS][0].classList.add('active');
        [...TABS_CONTENTS][0].classList.add('active');

        //for sub-tabs on mobile version
        const CLEAN_ALL_TABS = () => {
            [...TABS_BTNS].forEach((btn) => {
                btn.classList.remove('active');
            });
            [...TABS_CONTENTS].forEach((content) => {
                content.classList.remove('active');
            });
        }

        [...TABS_BTNS].forEach((btn, index) => {
            btn.addEventListener('click', (event) => {
                CLEAN_ALL_TABS();

                //clear exist active item and show first
                let currActiveContentItems = wrap.querySelector('.js-subtabs-item.active');
                if (currActiveContentItems) {
                    currActiveContentItems.classList.remove('active')
                }
                btn.classList.add('active');
                [...TABS_CONTENTS][index].classList.add('active');

            });
        });

    });


}

export {TABS, SubTabs, hideTabs}
