import { result } from "lodash";

export default (form) => {
    const regExp = {
        tel: RegExp('^\\+[0-9]{1}\\s\\((\\d+)\\) \\d{3}-\\d{2}-\\d{2}$'),
        email: RegExp('[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?'),
        name: RegExp('[a-zA-Z][a-zA-Z-_ ]{0,50}|[а-яА-Я][а-яА-Я-_ ]{0,50}'),
        address: RegExp('[а-яА-Яa-zA-Z][а-яА-Яa-zA-Z-1-9.#_]{2,50}'),
        message: RegExp('[a-zA-Z][a-zA-Z-_ ]{2,50}|[а-яА-Я][а-яА-Я-_ ]{2,50}'),
    }


    function MESSAGE(input, message) {
        let createEl = document.createElement("p");
        createEl.innerText = message;
        createEl.classList.add('error');
        input.parentElement.append(createEl);
        let errors = document.querySelector('.error');
        
        setTimeout(() => {
            input.parentElement.style.position = "relative";
            createEl.classList.add('active');
        }, 1);
        setTimeout(() => {
            createEl.remove();
        }, 1000);

    }
    
    let inputs = form.querySelectorAll('input'),
        result = true;
    inputs.forEach((item) => {
        if (item.getAttribute('data-rec')) {
            switch (item.getAttribute('type') || item.getAttribute('name')) {
                case ("text"):
                    if (!regExp['name'].test(item.value)) {
                        MESSAGE(item, 'Введите корректно');
                        result = false;
                    }
                    break;
                case ("email"):
                    if (!regExp['email'].test(item.value)) {
                        MESSAGE(item, 'Введите корректно Email');
                        result = false;
                    }
                    break;
                case ('tel'):
                    if (!regExp['tel'].test(item.value)) {
                        MESSAGE(item, 'Введите корректно телефон');
                        result = false;
                    }
                    break;
                case ('address'):
                    if (!regExp['address'].test(item.value)) {
                        MESSAGE(item, 'Введите корректно адрес');
                        result = false;
                    }
                    break;
                case ('checkbox'):
                    if (!item.checked) {
                        MESSAGE(item, 'Требуется соглашение с политикой конфиденциальности!');
                        result = false;
                    }
                    break;
                case ('password'):
                    if (!item.value) {
                        MESSAGE(item, 'Заполните поле');
                        result = false;
                    }
                default:
                    break
            }
        }
        
    });
    return result;
}